import { createContext, useContext, useEffect } from "react";
import { useGlobal } from "./use-global";
import { User, UserMetaData } from "@considr-it/storied-entities";
import { useAuth0 } from "@auth0/auth0-react";
import { trackEvent } from "../components/datadog-logs";
import { useLocalCached } from "./use-local-cached";

export const useAccountProvider = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { transport, isPWA } = useGlobal();

  const {
    localObject: account,
    setLocalObject: setAccount,
    serverObject: serverAccount,
    revalidate: revalidateAccount,
    isValidating: isValidatingAccount,
    isLoading: isLoadingAccount,
  } = useLocalCached<User>("account", "account");

  const {
    localObject: accountMetaData,
    revalidate: revalidateAccountMetaData,
  } = useLocalCached<UserMetaData>(
    account ? "userMetaData" : null,
    "account_metadata"
  );

  const login = async (hint: "signup" | "login" = "login") => {
    setAccount(null);

    trackEvent(hint);

    await loginWithRedirect({
      authorizationParams: {
        screen_hint: hint,
      },
      appState: {
        returnTo: `${window.location.origin}/login-callback?redirectUrl=${window.location.pathname}`,
      },
    });
  };

  const deleteAccount = async () => {
    trackEvent("account deleted");

    await transport.delete("/account");
    await handleLogout();
  };

  const handleLogout = async () => {
    setAccount(null);

    trackEvent("logout");

    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  useEffect(() => {
    revalidateAccount();
  }, [isAuthenticated]);

  useEffect(() => {
    const clientTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    if (
      !!clientTimeZone &&
      !!serverAccount &&
      !serverAccount.isAnonymous &&
      (serverAccount.timeZone !== clientTimeZone ||
        (!serverAccount.hasUsedPWA && isPWA))
    ) {
      transport
        .patch("/account", {
          payload: { timeZone: clientTimeZone, hasUsedPWA: true },
        })
        .then(() => {
          revalidateAccount();
        });
    }
  }, [serverAccount, transport]);

  useEffect(() => {
    revalidateAccountMetaData();
  }, [serverAccount]);

  useEffect(() => {
    if (!account) {
      localStorage.removeItem("google_oauth");
      localStorage.removeItem("linkedin_oauth");
      localStorage.removeItem("payment_info");
      localStorage.removeItem("account_metadata");
    }
  }, [account]);

  return {
    login,
    logout: handleLogout,
    deleteAccount,
    account,
    isValidatingAccount,
    isLoadingAccount,
    setAccount,
    revalidateAccount,

    accountMetaData,
    revalidateAccountMetaData,
  };
};

export const AccountProvider = ({ children }) => {
  const accountProvider = useAccountProvider();

  return (
    <AccountContext.Provider value={accountProvider}>
      {children}
    </AccountContext.Provider>
  );
};

export type AccountProps = ReturnType<typeof useAccountProvider>;
export const AccountContext = createContext<AccountProps>(null);
export const useAccount = () => useContext(AccountContext);
