import { useState } from "react";
// Custom hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
// Components
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
// Icons
import {
  ArrowUpperRightIcon,
  CheckmarkRoundedIcon,
} from "../../components/IconsSvg";
import { usePayment } from "../../hooks/use-payment";
import { BillingCycle, PaymentTier } from "@considr-it/storied-enums";
// Styles
import {
  PlansWrapper,
  Title,
  ItemWrapper,
  Card,
  LeftBlock,
  RightBlock,
  PriceWrapper,
  Description,
  PlanDescription,
  TextButton,
  FeatureBlock,
  SiteLink,
  Toggler,
  BillingButton,
} from "./plans-form.style";
import { useDocument } from "../../hooks/use-document";
import useConfirmModal from "../../hooks/use-confirm-modal";
import { useProcessingModal } from "../../hooks/use-processing-modal";
// Helpers

export const PlansForm = () => {
  const [billingCycleType, setBillingCycleType] = useState<BillingCycle>(
    BillingCycle.BILLING_CICLE_ANUALLY
  );
  const { account } = useAccount();
  const {
    paymentInfo,
    revalidatePaymentInfo,

    paidPlans,

    startCheckoutProcess,
    cancelSubscribtion,
    manageBilling,
  } = usePayment();

  const { freeTopicsCurrentMounthCount, trialTopicsCount } = useDocument();

  const { onPresentConfirmModal, onDismiss } = useConfirmModal();

  const {
    onPresent: onPresentUpdatingSubscription,
    onDismiss: onDismissUpdatingSubscription,
  } = useProcessingModal({
    text: "Updating Subscription...",
  });

  const plansInfo = {
    [PaymentTier.PAYMENT_TIER_FREE_EXPIRED]: {
      currentPlanName: "Basic Plan (used)",
      currentPlanCTA: "Upgrade to Pro",
      currentPlanTextButton: null,
      currentPlanDescriptionText1: `You have used all ${paymentInfo.freeTopicsThreshold} free topics this month.`,
      currentPlanDescriptionText2: `Next reset at: ${new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ).toLocaleDateString()}`,
    },
    [PaymentTier.PAYMENT_TIER_FREE]: {
      currentPlanName: "Basic Plan",
      currentPlanCTA: "Upgrade to Pro",
      currentPlanTextButton: null,
      currentPlanDescriptionText1: "Always free. No payment needed",
      currentPlanDescriptionText2: `You have used ${freeTopicsCurrentMounthCount}/${paymentInfo.freeTopicsThreshold} free documents this month.`,
    },
    [PaymentTier.PAYMENT_TIER_PRO_TRIAL]: {
      currentPlanName: "Pro Trial",
      currentPlanCTA: null,
      currentPlanTextButton: null,
      currentPlanDescriptionText1: `Trial still available for your next ${
        paymentInfo.trialTopicsThreshold - trialTopicsCount
      } documents.`,
      currentPlanDescriptionText2: "",
    },
    [PaymentTier.PAYMENT_TIER_PRO]: {
      currentPlanName: "Pro Plan",
      currentPlanCTA: !paymentInfo.cancelAtPeriodEnd ? null : "Renew Pro",
      currentPlanTextButton: !paymentInfo.cancelAtPeriodEnd
        ? "Cancel plan"
        : null,
      currentPlanDescriptionText1: !paymentInfo.cancelAtPeriodEnd
        ? `Automatic renewal on ${new Date(
            paymentInfo.subscriptionEndsAt
          ).toLocaleDateString()}`
        : `Your subscription ends on ${new Date(
            paymentInfo.subscriptionEndsAt
          ).toLocaleDateString()}`,
      currentPlanDescriptionText2: "",
    },
    [PaymentTier.PAYMENT_TIER_TEAM]: {
      currentPlanName: "Team",
      currentPlanCTA: null,
      currentPlanTextButton: "Cancel plan",
      currentPlanDescriptionText1: "",
      currentPlanDescriptionText2: "",
    },
  };

  const { isMobile } = useGlobal();

  const currentPlanInfo = plansInfo[paymentInfo.paymentTier];

  const toggleBillingCycleType = () =>
    setBillingCycleType((v) =>
      v === BillingCycle.BILLING_CICLE_MONTHLY
        ? BillingCycle.BILLING_CICLE_ANUALLY
        : BillingCycle.BILLING_CICLE_MONTHLY
    );
  const cancelConfirmModal = () => {
    onPresentConfirmModal({
      confirmationMessage: "Cancel Subscription",
      description: "Are you sure you want to cancel your subscription?",
      actions: [
        <div>
          <PrimaryButton
            fullWidth
            onClick={async () => {
              onPresentUpdatingSubscription();
              await cancelSubscribtion();
              onDismiss();
              await revalidatePaymentInfo();
              onDismissUpdatingSubscription();
            }}
          >
            Yes
          </PrimaryButton>
          <br />
          <SecondaryButton fullWidth onClick={onDismiss}>
            Close
          </SecondaryButton>
        </div>,
      ],
      withCloseBtn: false,
      disableOnHide: true,
    });
  };

  return (
    <PlansWrapper>
      {[
        PaymentTier.PAYMENT_TIER_FREE,
        PaymentTier.PAYMENT_TIER_FREE_EXPIRED,
        PaymentTier.PAYMENT_TIER_PRO_TRIAL,
      ].includes(paymentInfo.paymentTier as PaymentTier) && (
        <Toggler>
          <BillingButton
            $isActive={billingCycleType === BillingCycle.BILLING_CICLE_MONTHLY}
            onClick={toggleBillingCycleType}
          >
            {isMobile ? "Monthly" : "Monthly billing"}
          </BillingButton>
          <BillingButton
            $isActive={billingCycleType === BillingCycle.BILLING_CICLE_ANUALLY}
            onClick={toggleBillingCycleType}
          >
            {isMobile ? "Annually" : "Annually billing"}
          </BillingButton>
        </Toggler>
      )}
      <ItemWrapper>
        <Title>Current plan</Title>
        <Card>
          <LeftBlock>
            <Title>{currentPlanInfo.currentPlanName}</Title>
            <Description>
              {currentPlanInfo.currentPlanDescriptionText1}
            </Description>
            <Description>
              {currentPlanInfo.currentPlanDescriptionText2}
            </Description>
          </LeftBlock>
          <ReturnJSX if={currentPlanInfo?.currentPlanCTA}>
            <PrimaryButton
              onClick={async () => {
                onPresentUpdatingSubscription();
                await startCheckoutProcess(
                  PaymentTier.PAYMENT_TIER_PRO,
                  billingCycleType
                );

                await revalidatePaymentInfo();
                onDismissUpdatingSubscription();
              }}
              style={{ marginTop: isMobile ? 16 : 0 }}
              fullWidth={isMobile}
            >
              {currentPlanInfo?.currentPlanCTA}
            </PrimaryButton>
          </ReturnJSX>
          <ReturnJSX if={currentPlanInfo?.currentPlanTextButton}>
            <TextButton onClick={cancelConfirmModal}>
              {currentPlanInfo?.currentPlanTextButton}
            </TextButton>
          </ReturnJSX>
        </Card>
      </ItemWrapper>

      {paidPlans?.length > 0 && (
        <ItemWrapper>
          <Title>Other plans</Title>
          {paidPlans.map((p) => (
            <Card key={p.name} style={{ alignItems: "flex-start" }}>
              <LeftBlock>
                <Title style={{ height: isMobile ? 43 : "" }}>{p.name}</Title>
                {p.features.map((feature) => (
                  <FeatureBlock key={feature}>
                    <CheckmarkRoundedIcon />
                    <div>{feature}</div>
                  </FeatureBlock>
                ))}
                <SiteLink onClick={() => window.open(p.webflowLink, "_blank")}>
                  Find more details on plan features
                  <ArrowUpperRightIcon style={{ marginBottom: 2 }} />
                </SiteLink>
              </LeftBlock>
              <RightBlock>
                <PriceWrapper>
                  <Title>
                    {billingCycleType === BillingCycle.BILLING_CICLE_MONTHLY
                      ? p.priceMonthly
                      : p.priceAnnually}
                  </Title>
                  <PlanDescription>
                    {billingCycleType === BillingCycle.BILLING_CICLE_MONTHLY
                      ? p.descriptionMonthly
                      : p.descriptionAnnually}
                  </PlanDescription>
                </PriceWrapper>
                <PrimaryButton
                  fullWidth={isMobile}
                  disabled={
                    plansInfo[p.name] === currentPlanInfo.currentPlanName
                  }
                  onClick={
                    p.name === "Team"
                      ? () => window.open(p?.teamFormLink, "_blank")
                      : async () => {
                          onPresentUpdatingSubscription();
                          await startCheckoutProcess(
                            p?.paymentTier,
                            billingCycleType
                          );

                          await revalidatePaymentInfo();
                          onDismissUpdatingSubscription();
                        }
                  }
                  style={{ marginTop: isMobile ? 16 : 8 }}
                >
                  {plansInfo[p.name] === currentPlanInfo.currentPlanName
                    ? "You are on this plan"
                    : p.name === "Team"
                    ? "Contact us"
                    : "Choose this plan"}
                </PrimaryButton>
              </RightBlock>
            </Card>
          ))}
        </ItemWrapper>
      )}

      {paymentInfo.status !== "canceled" && (
        <ItemWrapper>
          <Title>Billing information</Title>
          <Card>
            <LeftBlock>
              <Title>{account.profile.name}</Title>
              <Description>{account.mainEmail}</Description>
            </LeftBlock>
            <TextButton onClick={() => manageBilling()}>
              Manage payment details
            </TextButton>
          </Card>
        </ItemWrapper>
      )}
    </PlansWrapper>
  );
};
