import { CustomWord } from "@considr-it/storied-entities";
import { ApiKeyType } from "@considr-it/storied-enums";
import { trackError, trackEvent } from "@considr-it/storied-shared";
import {
  createClient,
  LiveClient,
  LiveTranscriptionEvents,
} from "@deepgram/sdk";
import { AxiosInstance } from "axios";

export class DeepGramStt {
  socket: LiveClient = null;
  transcript: string = "";
  onTranscribe: (_: string) => void;
  init = async (
    onTranscribe: (_: string) => void,
    transport: AxiosInstance,
    language: string,
    customWords: string[],
    userName: string
  ) => {
    this.transcript = "";
    this.onTranscribe = onTranscribe;

    const { data: apiKey } = await transport.get(
      `/apiKey/${ApiKeyType.DEEPGRAM_API_KEY}`
    );

    const keywords = [
      "Vnote:0.5",
      "vnote.ai:0.5",
      ...Array.from(new Set(customWords.map((cw) => `${cw}:0.5`))),
      ...(userName ? userName.split(" ").map((n) => `${n}:0.5`) : []),
    ];

    const client = createClient(apiKey);

    this.socket = client.listen.live({
      smart_format: true,
      model: "nova-2-general",
      language: language || "en-US",
      interim_results: true,
      keywords,
      punctuate: true,
    });

    await new Promise((resolve) => {
      this.socket.addListener("open", async () => {
        const readyInterval = setInterval(() => {
          if (this.socket.getReadyState() === 1) {
            trackEvent("deepgram_initialised");
            clearInterval(readyInterval);

            resolve(true);
          }
        }, 500);

        this.socket.addListener("close", async () => {
          trackEvent("deepgram_closing");
          clearInterval(readyInterval);

          this.socket.removeAllListeners();
        });

        this.socket.addListener("error", async (error) => {
          clearInterval(readyInterval);
          trackError("deepgram_error", error);

          this.end();
        });

        this.socket.addListener(LiveTranscriptionEvents.Transcript, (data) => {
          const { type } = data;
          switch (type) {
            case "Results":
              if (data.is_final === true) {
                const liveTranscript =
                  data?.channel?.alternatives[0]?.transcript || "";

                if (!!liveTranscript && liveTranscript.length > 0) {
                  if (
                    this.transcript.length > 0 &&
                    this.transcript[this.transcript.length - 1] !== "\n"
                  ) {
                    this.transcript += " ";
                  }

                  this.transcript += liveTranscript;
                  this.onTranscribe(this.transcript);
                }
              }

              break;
            case "Metadata":
              break;
            default:
              trackEvent("deepgram_unknown_packet", { data });
              break;
          }
        });
      });
    });
  };

  end = () => {
    let transcript = this.transcript;

    if (this.socket) {
      this.socket.finish();
      this.socket.removeAllListeners();
      this.socket = null;
    }

    transcript = transcript.trim();
    if (
      transcript.length > 0 &&
      !["?", "!", "."].includes(transcript[transcript.length - 1])
    ) {
      transcript += ".";
    }

    if (this.onTranscribe) {
      this.onTranscribe(transcript);
    }

    this.transcript = "";

    return transcript;
  };
}
