import { createContext, useContext, useEffect, useState } from "react";
import { useAccount, useGlobal } from "@considr-it/storied-shared";
import { Transport } from "@considr-it/storied-shared";
import { Idea, Topic } from "@considr-it/storied-entities";
import { usePayment } from "./use-payment";

export const useDocumentProvider = () => {
  const { transport } = useGlobal();
  const { account } = useAccount();
  const { revalidatePaymentInfo } = usePayment();
  const [currentTopicId, setCurrentTopicId] = useState<string>("");
  const [currentIdea, setCurrentIdea] = useState<Idea>(null);
  const [topicsCount, setTopicsCount] = useState<number>(
    Number(localStorage.getItem("topics_count") || 0)
  );

  const [freeTopicsCurrentMounthCount, setFreeTopicsCurrentMounthCount] =
    useState<number>(
      Number(localStorage.getItem("free_topics_current_month_count") || 0)
    );

  const [trialTopicsCount, setTrialTopicsCount] = useState<number>(
    Number(localStorage.getItem("trial_topics_count") || 0)
  );

  const {
    data: currentTopic,
    revalidate: revalidateTopic,
    isLoading: isLoadingTopic,
    isValidating: isValidatingTopic,
  } = Transport.useTransportSWR<Topic>(
    transport,
    currentTopicId ? `/topic/${currentTopicId}` : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    }
  );

  const refreshTopicsCount = () => {
    transport
      .get<{
        topicsCount: number;
        freeTopicsCurrentMounthCount: number;
        trialTopicsCount: number;
      }>("/topicsCount")
      .then((resp) => {
        setTopicsCount(resp?.data.topicsCount || 0);
        setFreeTopicsCurrentMounthCount(
          resp?.data.freeTopicsCurrentMounthCount || 0
        );
        setTrialTopicsCount(resp?.data.trialTopicsCount || 0);

        revalidatePaymentInfo();
      });
  };

  useEffect(() => {
    refreshTopicsCount();
  }, [account]);

  useEffect(() => {
    if (topicsCount !== undefined && topicsCount !== null) {
      localStorage.setItem("topics_count", topicsCount.toString());
    }
  }, [topicsCount]);

  useEffect(() => {
    if (
      freeTopicsCurrentMounthCount !== undefined &&
      freeTopicsCurrentMounthCount !== null
    ) {
      localStorage.setItem(
        "free_topics_current_month_count",
        freeTopicsCurrentMounthCount.toString()
      );
    }
  }, [freeTopicsCurrentMounthCount]);

  useEffect(() => {
    if (trialTopicsCount !== undefined && trialTopicsCount !== null) {
      localStorage.setItem("trial_topics_count", trialTopicsCount.toString());
    }
  }, [trialTopicsCount]);

  return {
    currentTopic,
    revalidateTopic,

    refreshTopicsCount,

    topicsCount,
    setTopicsCount,
    freeTopicsCurrentMounthCount,
    trialTopicsCount,

    currentTopicId,
    setCurrentTopicId,
    isLoadingTopic,
    isValidatingTopic,

    currentIdea,
    setCurrentIdea,
  };
};

export const DocumentProvider = ({ children }) => {
  const documentProvider = useDocumentProvider();

  return (
    <DocumentContext.Provider value={documentProvider}>
      {children}
    </DocumentContext.Provider>
  );
};

export type DocumentProps = ReturnType<typeof useDocumentProvider>;
export const DocumentContext = createContext<DocumentProps>(null);
export const useDocument = () => useContext(DocumentContext);
