import styled from "styled-components";
// Components
import { BasicModal } from "../BasicModal";
// Styles
import { StyledSpinner } from "../../ProcessingCircle/processing-circle.style";
const DescriptionText = styled.p`
  color: #101212;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  margin-bottom: 0;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const ProcessingModal = ({ text = "" }) => (
  <BasicModal show={true}>
    <Wrapper>
      <StyledSpinner animation="border" role="status" />
      {text && <DescriptionText>{text}</DescriptionText>}
    </Wrapper>
  </BasicModal>
);