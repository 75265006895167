import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// Components
import { SecondaryButton } from "../components/Buttons";
// Custom hooks
import {
  trackError,
  useGlobal,
  trackEvent,
  usePlayer,
} from "@considr-it/storied-shared";
import { useDocument } from "./use-document";
import { useTimer } from "use-timer";
import { useMicRecorder } from "./use-mic-recorder";
import { useConfirmModal } from "./use-confirm-modal";
// Constants
import { LiveQuestion, LiveStory, Topic } from "@considr-it/storied-entities";
import {
  CustomStartType,
  RecordingObjectType,
  TTSType,
} from "@considr-it/storied-enums";
// Utils
import { timeString } from "@considr-it/storied-utils";

export const useMakeRecord = (customStartType: CustomStartType) => {
  const navigate = useNavigate();

  const liveStoryIdRef = useRef<string>(null);
  // State
  const [isInitialisingLiveStory, setIsInitialisingLiveStory] =
    useState<boolean>(false);
  const [isGeneratingQuestion, setIsGeneratingQuestion] =
    useState<boolean>(false);

  const [liveTranscript, setLiveTranscript] = useState<string>("");
  const [currentLiveQuestion, setCurrentLiveQuestion] =
    useState<Partial<LiveQuestion>>(null);
  const [currentLiveStory, setCurrentLiveStory] = useState<LiveStory>(null);

  // Refs
  const currentTranscriptChunk = useRef<string>("");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // Hooks
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const { transport, isPWA, appVisibility, isMobile } = useGlobal();
  const {
    time,
    start: startTimer,
    pause: pauseTimer,
    reset: resetTimer,
  } = useTimer();
  const {
    start,
    stop,
    pause,
    isRecording,
    isPausedRecording,
    defaultAudioDevice,
  } = useMicRecorder();

  const {
    controls: { play: playQuestionAudio, stop: stopQuestionAudio },
    state: { canPlay: canPlayQuestionAudio },
    setup: setupQuestionAudio,
    reset: resetQuestionAudio,
  } = usePlayer();

  const [hasRequestedQuestion, setHasRequestedQuestion] =
    useState<boolean>(false);
  const { setCurrentTopicId, setTopicsCount, topicsCount } = useDocument();

  const startRecording = async (
    payload: {
      isDraft: boolean;
      outline: string;
    } = { isDraft: false, outline: "" }
  ) => {
    if (!!currentLiveQuestion?.spokenQuestion) {
      currentTranscriptChunk.current = "";
    }

    setLiveTranscript("");

    if (!isPausedRecording) {
      const navBarInstance = document.getElementById("bottom-nav-bar");
      navBarInstance?.classList.add("fade-out");

      await setupQuestionAudio({
        itemId: "question.mp3",
        itemModel: "local",
      });

      if (!currentLiveStory) {
        setIsInitialisingLiveStory(true);
        const { data } = await transport.post<LiveStory>("/createLiveStory", {
          customStartType,
          ...payload,
        });

        liveStoryIdRef.current = data.id;

        setCurrentLiveStory(data);
        setIsInitialisingLiveStory(false);
      }

      currentTranscriptChunk.current = "";
    } else {
      await resetQuestionAudio();
    }

    await start(
      RecordingObjectType.TOPIC,
      onEnd,
      onTranscribe,
      canvasRef.current
    );

    startTimer();
  };

  const pauseRecording = async (forcedToBackground = false) => {
    pauseTimer();

    await resetQuestionAudio();

    setCurrentLiveQuestion(null);
    setIsGeneratingQuestion(true);
    const transcript = await pause();
    if (!!transcript) {
      currentTranscriptChunk.current += `${transcript}\n`;
      try {
        if (transcript?.split(" ")?.length >= 20 && topicsCount === 0) {
          window.gtag("config", "AW-11133507454");
          window.gtag("event", "conversion", {
            send_to: "AW-11133507454/Bo4iCLfbmMkZEP6u77wp",
          });
        }

        const { data: liveQuestion } = await transport.post<LiveQuestion>(
          "/addTranscriptToLiveStory",
          {
            liveStoryId: currentLiveStory.id,
            subTranscript: transcript,
            noQuestion: forcedToBackground,
          }
        );

        if (liveQuestion) {
          await handleNewLiveQuestion(liveQuestion);
        }
      } catch (err) {
        trackError("failed_add_transcript_live_story", err);
      }
    }
    setIsGeneratingQuestion(false);
  };

  const getQuestion = async () => {
    await resetQuestionAudio();

    setCurrentLiveQuestion(null);
    setIsGeneratingQuestion(true);
    try {
      const { data: liveQuestion } = await transport.post<LiveQuestion>(
        "/getLiveQuestion",
        {
          liveStoryId: currentLiveStory.id,
        }
      );

      await handleNewLiveQuestion(liveQuestion);
    } catch (err) {
      trackError("failed_get_new_question", err);
    }
    setIsGeneratingQuestion(false);
  };

  const stopRecording = async () => {
    await resetQuestionAudio();
    await stop();
  };

  // Internal functions
  const handleNewLiveQuestion = async (liveQuestion: LiveQuestion) => {
    if (liveQuestion.spokenQuestion) {
      setHasRequestedQuestion(true);

      await playQuestionAudio({
        itemId: liveQuestion.id,
        itemModel: TTSType.LiveQuestion,
        isTemporary: true,
      });
    }
    setLiveTranscript("");
    setCurrentLiveQuestion(liveQuestion);
  };

  const onEnd = async (objectId: string) => {
    const { data: topic } = await transport.post<Topic>("/createTopic", {
      topicId: objectId,
      liveStoryId: liveStoryIdRef.current,
    });

    setCurrentTopicId(topic.id);
    setTopicsCount((prev) => prev + 1);

    const navBarInstance = document.getElementById("bottom-nav-bar");

    if (navBarInstance) {
      navBarInstance.classList.remove("fade-out");
    }

    navigate(`topic/${topic.id}`);
  };

  const onTranscribe = async (transcript: string) => {
    setLiveTranscript(transcript);
  };

  const cleanupRecordingFlow = () => {
    setCurrentLiveStory(null);
    setCurrentLiveQuestion(null);

    resetTimer();

    resetQuestionAudio();
    stop(true);
    canvasRef.current = null;
  };

  useEffect(() => {
    if (currentLiveStory) {
      liveStoryIdRef.current = currentLiveStory.id;
    } else {
      liveStoryIdRef.current = null;
    }
  }, [currentLiveStory]);

  useEffect(() => {
    if (isRecording && !defaultAudioDevice) {
      pauseRecording(true);
    }
  }, [defaultAudioDevice, isRecording]);

  useEffect(() => {
    if (isRecording && isPWA && isMobile && !appVisibility) {
      trackEvent("force_pause_recording");
      pauseRecording(true);

      onPresentConfirmModal({
        confirmationMessage:
          "If you go away from the app or lock your phone your recording will be paused.\nYou can adjust this by going to Settings > Display & Brightness > Auto-Lock, then choose a longer length of time.",
        actions: [<SecondaryButton onClick={onDismiss}>Close</SecondaryButton>],
      });
    }
  }, [isPWA, appVisibility, isRecording, isMobile]);

  return {
    isInitialisingLiveStory,
    setIsInitialisingLiveStory,

    isGeneratingQuestion,
    canPlayQuestionAudio,
    liveTranscript,
    time: timeString(time),
    currentTranscriptChunk,
    currentLiveQuestion,
    setCurrentLiveQuestion,

    canvasRef,
    currentLiveStory,
    setCurrentLiveStory,
    stopRecording,
    getQuestion,
    pauseRecording,
    startRecording,
    playQuestionAudio,
    stopQuestionAudio,

    hasRequestedQuestion,

    cleanupRecordingFlow,
  };
};
