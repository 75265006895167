import { PaymentTier } from "@considr-it/storied-enums";
import { usePayment } from "../../hooks/use-payment";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
import { PrimaryButton } from "../Buttons";
import { useDocument } from "../../hooks/use-document";
import { useAccount } from "@considr-it/storied-shared";
import { ConfirmationModal } from "../Modals/ConfirmModal";

export const TrialAccountNotification = () => {
  const { paymentInfo } = usePayment();
  const { trialTopicsCount } = useDocument();
  const { account } = useAccount();
  const { onDismiss } = useConfirmModal();

  if (
    !account.isAnonymous &&
    paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_PRO_TRIAL &&
    !localStorage.getItem("trial_account_notification") &&
    trialTopicsCount === 1
  ) {
    return (
      <ConfirmationModal
        params={{
          confirmationMessage: "You've started your free trial!",
          description:
            "You can still use all of our AI Editing Tools and integrations to make writing work for you. You get 5 free documents. This is your first!",
          actions: [
            <PrimaryButton
              fullWidth
              onClick={() => {
                localStorage.setItem("trial_account_notification", "true");
                onDismiss();
              }}
            >
              Let's Go
            </PrimaryButton>,
          ],
          withCloseBtn: false,
          disableOnHide: true,
        }}
      />
    );
  } else {
    return <></>;
  }
};
