import {
  useAccount,
  useAnonymousId,
  useGlobal,
} from "@considr-it/storied-shared";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const LoginCallback = () => {
  const navigate = useNavigate();
  const [anonymousId] = useAnonymousId();
  const { account } = useAccount();
  const { transport, isPrerender } = useGlobal();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (account?.isAnonymous === false && !isPrerender) {
      const redirectUrl = searchParams.get("redirectUrl");

      transport
        .post("/copyFromAnon", {
          anonId: anonymousId,
        })
        .then(() => {
          navigate(redirectUrl);
        });
    }
  }, [account, isPrerender]);

  return <></>;
};
