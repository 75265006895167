import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Accordion } from "react-bootstrap";
// Custom Hooks
import { useGlobal, Transport, useAccount } from "@considr-it/storied-shared";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
import { useEditor } from "../../hooks/use-editor";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
// Components
import { HeaderIconWrapper } from "../../components/HeaderIconWrapper";
import { TextToSpeech } from "../../components/TextToSpeech";
import MyCard from "../../components/MyCard";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import {
  HeaderContainer,
  HeaderIconsWrapper,
} from "../../components/OutputViewer/output-viewer.style";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { ProcessingCircle } from "../../components/ProcessingCircle";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
// Utils
import { timeAgo } from "../../utils/time-ago";
// Storied libs
import {
  Image,
  SharedOutput,
  SharedOutputAnnotation,
  SharedOutputReview,
} from "@considr-it/storied-entities";
import {
  ImageType,
  RecordingObjectType,
  TTSType,
} from "@considr-it/storied-enums";
// Styles
import {
  LinkToDocs,
  ShareableDocumentWrapper,
  DocInfo,
  DocHint,
  DocInfoRow,
  CopyLinkButtonWrapper,
  NoContentWrap,
  TitleWrapper,
  ToolDescription,
} from "./share-document.style";
// Logs
import { trackError } from "@considr-it/storied-shared";
// Icons
import { BinIcon, CopyIcon, TickIcon } from "../../components/IconsSvg";
// Pictures
import NoContentPic from "./noContentPic.svg";
import TypeOrRecordForm from "../../components/TypeOrRecordForm";
import { ReviewsList } from "./ReviewsList";
import { CollapsableMenu } from "../../components/CollapsableMenu";
import LoginButton from "../../components/LoginButton";
import { truncate } from "../../utils/utils";

export const ShareableDocument: React.FC = () => {
  const { shortId } = useParams();
  const [accordionElem, setAccordionElem] = useState<null | string>(null);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const { transport, isMobile, isPrerender, isSmallMobile } = useGlobal();
  const { account } = useAccount();
  const navigate = useNavigate();
  const {
    deletingOutput,
    setDeletingOutput,
    updatingOutput,
    setUpdatingOutput,
    revalidateOutput,
    setAnnotationsList,
    currentHighlighted,
    setCurrentHighlighted,
    annotationsList,
    currentSelectedAnnotation,
    setCurrentSelectedAnnotation,
  } = useIdeaFlow();

  const [imageUrl, setImageUrl] = useState<string>(null);
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();

  const {
    data: sharedOutput,
    isLoading: isLoadingSharedOutput,
    revalidate: revalidateSharedOutput,
  } = Transport.useTransportSWR<SharedOutput>(
    transport,
    `/sharedOutputByShortId/${shortId}`,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    }
  );

  const updateOutputText = async (_oldText: string, newText: string) => {
    setUpdatingOutput(true);

    await transport.patch(`/sharedOutput/${sharedOutput.id}`, {
      payload: {
        text: newText,
      },
    });

    setUpdatingOutput(false);
  };

  const { Editor, initializeEditor } = useEditor({
    initialText: sharedOutput?.text,
    updateTextRequest: updateOutputText,
    editModeCondition: account?.id === sharedOutput?.owner,
    isUpdating: updatingOutput,
    highlightTextOnSelect: !account.isAnonymous,
  });

  useEffect(() => {
    if (sharedOutput) {
      if (sharedOutput.text && !isMobile) {
        setTimeout(() => setAccordionElem("1"), 400);
      }

      transport
        .get<Image>(`/image/${ImageType.SharedOutputCover}/${sharedOutput.id}`)
        .then((resp) => setImageUrl(resp.data.imageFilePublicUrl || ""));

      setAnnotationsList({
        annotations: (
          sharedOutput.sharedOutputAnnotations as SharedOutputAnnotation[]
        )
          .filter((soa) => soa.archived !== true)
          .map((soa) => {
            return { ...soa.annotationData, id: soa.id };
          }),
        reason: "init",
      });
    }

    initializeEditor();
  }, [sharedOutput]);

  useEffect(() => {
    if (annotationsList?.reason === "editorUpdate") {
      let debounceTimer: NodeJS.Timeout;
      const debouncedSave = () => {
        clearInterval(debounceTimer);
        debounceTimer = setTimeout(() => {
          transport.post("/updateAnnotations", {
            sharedOutputId: sharedOutput.id,
            annotationsList: annotationsList.annotations,
          });
        }, 1500);
      };
      debouncedSave();

      return () => {
        if (debounceTimer) {
          clearInterval(debounceTimer);
        }
      };
    }
  }, [annotationsList, transport]);

  const deleteOutput = async () => {
    onPresentConfirmModal({
      confirmationMessage:
        "Are you sure you want to delete this shared output?",
      actions: [
        <PrimaryButton
          onClick={async () => {
            setDeletingOutput(true);
            onDismiss();
            await transport.delete(`/sharedOutput/${sharedOutput.id}`);

            revalidateOutput();
            setDeletingOutput(false);
            navigate(`/topic/${sharedOutput.topic}`);
          }}
        >
          Confirm
        </PrimaryButton>,
        <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>,
      ],
    });
  };

  const copyLinkToClipboard = async () => {
    navigator.clipboard
      .writeText(
        `${sharedOutput.tldr || ""}\n${window.location.origin}/s/${shortId}`
      )
      .then((_) => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 1500);
      })
      .catch((err) => {
        trackError("clipboard_shared_output_error", err);
      });
  };

  if (isPrerender) {
    if (!sharedOutput || imageUrl === null) {
      return (
        <Helmet>
          <script>window.prerenderReady = false;</script>
        </Helmet>
      );
    } else {
      return (
        <Helmet>
          <script>window.prerenderReady = true;</script>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={sharedOutput.title} />
          <meta property="og:description" content={sharedOutput.tldr} />
          <meta property="og:site_name" content="Vnote" />
          {imageUrl && <meta property="og:image" content={imageUrl} />}
          {imageUrl && (
            <meta name="twitter:card" content="summary_large_image" />
          )}
        </Helmet>
      );
    }
  }

  const CopyLinkButton = (
    <CopyLinkButtonWrapper>
      <SecondaryButton fullWidth noIconFill onClick={copyLinkToClipboard}>
        {linkCopied ? (
          <TickIcon width={20} height={20} />
        ) : (
          <CopyIcon width={20} height={20} />
        )}
        {linkCopied ? "Copied" : "Copy shareable link"}
      </SecondaryButton>
    </CopyLinkButtonWrapper>
  );

  if (isLoadingSharedOutput) {
    return <LoadingAnimation circle />;
  }

  if (!sharedOutput) {
    return (
      <NoContentWrap>
        <img src={NoContentPic} alt="No content" />
        <h2>This content does not exist. The user might have deleted it.</h2>
      </NoContentWrap>
    );
  }

  const typeOrRecordForm = (
    <TypeOrRecordForm
      textarea
      onEntry={async (objectId: string) => {
        let annotation = currentSelectedAnnotation;

        if (!annotation && currentHighlighted) {
          const { data } = await transport.post<SharedOutputAnnotation>(
            "/createSharedOutputAnnotation",
            {
              sharedOutputId: sharedOutput.id,
              from: currentHighlighted.selection.from,
              to: currentHighlighted.selection.to,
              text: currentHighlighted.text,
            }
          );

          annotation = {
            id: data.id,
            from: data.annotationData.from,
            to: data.annotationData.to,
            text: data.annotationData.text,
          };

          setAnnotationsList((prev) => {
            const newArray = [...prev.annotations];
            newArray.push(annotation);

            return { annotations: newArray, reason: "addedAnnotation" };
          });

          setCurrentSelectedAnnotation(annotation);
        }

        await transport.post<SharedOutputReview>("/createSharedOutputReview", {
          sharedOutputId: sharedOutput.id,
          sharedOutputReviewId: objectId,
          annotationId: annotation?.id,
        });

        setCurrentHighlighted(null);

        await revalidateSharedOutput();
      }}
      placeHolderText="Type here or record => "
      objectType={RecordingObjectType.SHARED_OUTPUT_REVIEW}
      style={{ marginTop: 0, marginBottom: 0 }}
    />
  );

  const iconSize = isSmallMobile
    ? { width: 20, height: 20 }
    : { width: 24, height: 24 };

  return (
    <ShareableDocumentWrapper>
      <MyCard>
        <HeaderContainer style={{ marginBottom: 24, alignItems: "center" }}>
          <ReturnJSX if={account.id === sharedOutput.owner}>
            <LinkToDocs
              onClick={() =>
                (window.location.href = `${window.location.origin}/topic/${sharedOutput.topic}/${sharedOutput.originalOutput}`)
              }
            >
              Go to the original output
            </LinkToDocs>
            <HeaderIconsWrapper>
              <TextToSpeech
                objectId={sharedOutput.id}
                objectType={TTSType.SharedOutput}
                disabled={false}
              />
              <HeaderIconWrapper
                onClick={deleteOutput}
                title="Delete Output"
                Icon={<BinIcon className="output-delete" />}
              />
            </HeaderIconsWrapper>
          </ReturnJSX>
          <ReturnJSX if={account.id !== sharedOutput.owner}>
            <HeaderIconsWrapper
              className="output-viewer-tools"
              style={{ marginLeft: "auto" }}
            >
              <TextToSpeech
                objectId={sharedOutput.id}
                objectType={TTSType.SharedOutput}
                disabled={false}
                sizeIncreased
              />
            </HeaderIconsWrapper>
          </ReturnJSX>
        </HeaderContainer>
        {deletingOutput && <ProcessingCircle subtext={"Deleting Output"} />}
        {!deletingOutput && (
          <div>
            {imageUrl && (
              <img
                width={"100%"}
                src={imageUrl}
                style={{ marginBottom: isMobile ? "24px" : "32px" }}
              />
            )}
            {Editor}
          </div>
        )}
      </MyCard>
      {isMobile && CopyLinkButton}
      {!isMobile && (
        <Accordion activeKey={accordionElem}>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() =>
                setAccordionElem((el) => (el === "0" ? null : "0"))
              }
            >
              Document info
            </Accordion.Header>
            <Accordion.Body>
              <DocInfo>
                <DocInfoRow>
                  <span>Created by: </span>
                  {sharedOutput.ownerName}
                </DocInfoRow>
                <DocInfoRow>
                  <span>Created on: </span>
                  {new Date(sharedOutput.created).toLocaleString()}
                </DocInfoRow>
                <DocInfoRow>
                  <span>Last modified: </span>
                  {timeAgo(new Date(sharedOutput.updated))}
                </DocInfoRow>
              </DocInfo>
              {account.id === sharedOutput.owner && (
                <DocHint>
                  <p>As the creator, you can delete and edit the document</p>
                  <p>
                    Other users will only be able to view and play the sound
                  </p>
                </DocHint>
              )}
              {!isMobile && CopyLinkButton}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              onClick={() =>
                setAccordionElem((el) => (el === "1" ? null : "1"))
              }
            >
              {(currentHighlighted || currentSelectedAnnotation) &&
              !account.isAnonymous
                ? "Comment"
                : "Leave High Level Feedback"}
            </Accordion.Header>
            <Accordion.Body>
              {!account.isAnonymous ? (
                <>
                  {!currentSelectedAnnotation && (
                    <>
                      <ToolDescription className="mb-0">
                        {currentHighlighted
                          ? `"${truncate(currentHighlighted.text, 100)}"`
                          : "Record feedback or comment on highlighted lines."}
                      </ToolDescription>
                      {!currentHighlighted && (
                        <ReviewsList
                          sharedOutputReviewsIds={
                            sharedOutput.sharedOutputReviews as string[]
                          }
                        />
                      )}
                      <br />
                      {typeOrRecordForm}
                    </>
                  )}
                  {currentSelectedAnnotation && (
                    <>
                      <ReviewsList
                        sharedOutputReviewsIds={
                          (
                            sharedOutput.sharedOutputAnnotations as SharedOutputAnnotation[]
                          ).find(
                            (soa) => soa.id === currentSelectedAnnotation.id
                          )?.sharedOutputReviews as string[]
                        }
                      />
                      {typeOrRecordForm}
                    </>
                  )}
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoginButton />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {isMobile && (
        <CollapsableMenu
          defaultExpanded={true}
          dataToRefresh={currentSelectedAnnotation || currentHighlighted}
        >
          {!currentSelectedAnnotation ? (
            <>
              <TitleWrapper>
                <p>
                  {account.isAnonymous
                    ? "Login to Leave Feedback"
                    : currentHighlighted
                    ? "Comment on this section"
                    : "Leave High Level Feedback"}
                </p>
              </TitleWrapper>
              <ToolDescription className="mb-0">
                {currentHighlighted
                  ? `"${truncate(currentHighlighted.text, 100)}"`
                  : "Record feedback or comment on highlighted lines."}
              </ToolDescription>
              {account.isAnonymous ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoginButton />
                </div>
              ) : (
                <>
                  {!currentHighlighted && (
                    <ReviewsList
                      sharedOutputReviewsIds={
                        sharedOutput.sharedOutputReviews as string[]
                      }
                    />
                  )}

                  {typeOrRecordForm}
                </>
              )}
            </>
          ) : (
            <>
              <ReviewsList
                sharedOutputReviewsIds={
                  (
                    sharedOutput.sharedOutputAnnotations as SharedOutputAnnotation[]
                  ).find((soa) => soa.id === currentSelectedAnnotation.id)
                    ?.sharedOutputReviews as string[]
                }
              />
              {typeOrRecordForm}
            </>
          )}
        </CollapsableMenu>
      )}
    </ShareableDocumentWrapper>
  );
};
