import styled from "styled-components";

export const SharableLinkWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  background: #fafafa;
  margin-bottom: 24px;
  color: #6f6f6f;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  a {
    color: #ff6d34;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const HeaderIconsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: 24px;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;

export const SideUIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SideUIHint = styled.div`
  color: #6f6f6f;
  text-align: center;
  font-family: "Metropolis";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  justify-content: center;
`;

export const SideUIOrElementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 22px;
`;

export const SideUIOrElement = styled.div`
  color: #ababab;
  font-family: "Metropolis";
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
`;

export const SideUILine = styled.div`
  background: #ababab;
  height: 1px;
  width: 100%;
`;

export const TabsWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  gap: 8px;
  top: -52px;
  left: 0;
  @media screen and (max-width: 360px) {
    top: -38px;
  }
`;

export const Tab = styled.div<{ $isActive: boolean; $isDisabled: boolean }>`
  background: ${(props) => props.$isActive ? "#FFF" : "#FFF8F5"};
  border-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.04) 0px -17px 15px 1.5px;
  cursor: pointer;
  color: ${(props) =>
    props.$isActive ? "#FF6D34" : props.$isDisabled ? "#252525" : "#A7A7A7" };
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  @media screen and (max-width: 767px) {
    width: 100%;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media screen and (max-width: 360px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: -0.308px;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 16px;
  }
`;

export const DropZoneWrapper = styled.div`
  height: 200px;
  border-radius: 16px;
  border: 1px dotted grey;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #A7A7A7;
`;