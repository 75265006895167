import { useEffect, useMemo, useState } from "react";
import {
  IdeaFlowWrapper,
  RecordButtonWrapper,
  RightLine,
  StartBtn,
  StartBtnIcon,
  StartBtnWrapper,
  WaveArcsCanvas,
  RightLineWrapper,
  RightLineLabel,
  ActionsWrapper,
  TitleWrapper,
  IntroSubtitle,
  BottomButtonsWrapper,
  HintWrapper,
  LinkWrapper,
} from "./idea-flow.style";
import { Stack } from "react-bootstrap";
import { OnboardingFlowType, PaymentTier } from "@considr-it/storied-enums";
import { getCustomStartConfig, useGlobal } from "@considr-it/storied-shared";
import { ProcessingCircle } from "../ProcessingCircle";
import MicrophoneIcon from "./microphone.svg";
import PauseIcon from "./pause.svg";
import RightLineImage from "./rightLineImage.webp";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { WriteNote } from "../WriteNote";
import { InputLanguages } from "../InputLanguages";
// Icons
import RepeatIcon from "./repeatIcon.svg";
import DocIcon from "./docIcon.svg";
import { OnboardingCarousel } from "../OnboardingCarousel";
import { usePayment } from "../../hooks/use-payment";
import { useUpgradeToProModal } from "../../hooks/use-upgrade-to-pro-modal";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { QuestionAudioPlayer } from "../QuestionAudioPlayer";
import { useMicRecorder } from "../../hooks/use-mic-recorder";
import { IdeaIcon } from "../IconsSvg";
import { MagicOutlineModal } from "../Modals/MagicOutlineModal/MagicOutlineModal";

const IdeaFlow = () => {
  const [magicOutlineModal, setMagicOutlineModal] = useState<boolean>(false);
  const {
    initialisingWritingFeedback,
    setInitialisingWritingFeedback,

    customStartType,
    setCustomStartType,

    record,
  } = useIdeaFlow();

  const {
    pauseRecording,
    startRecording,
    stopRecording,
    getQuestion,

    hasRequestedQuestion,

    currentLiveStory,

    currentLiveQuestion,
    setCurrentLiveQuestion,

    canvasRef,

    isInitialisingLiveStory,
    liveTranscript,
    isGeneratingQuestion,
    time,
    currentTranscriptChunk,

    cleanupRecordingFlow,
  } = record;

  const { isInitialising, isPausing, isRecording, isPausedRecording, isBusy } =
    useMicRecorder();

  const { isMobile, isSmallMobile } = useGlobal();

  const { paymentInfo } = usePayment();
  const { onPresent: showUpgradeToProModal } = useUpgradeToProModal();

  useEffect(() => {
    setCustomStartType(null);

    return () => {
      cleanupRecordingFlow();
    };
  }, []);

  useEffect(() => {
    setCurrentLiveQuestion(getCustomStartConfig(customStartType).liveQuestion);
  }, [customStartType]);

  useEffect(() => {
    if (isMobile && !isSmallMobile) {
      document.body.style.position = "fixed";
      document.body.style.top = "0";
      document.body.style.bottom = "0";
      document.body.style.left = "0";
      document.body.style.right = "0";
    }
    return () => {
      if (
        isMobile &&
        !isSmallMobile &&
        document.body.style.position === "fixed"
      ) {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.bottom = "";
        document.body.style.left = "";
        document.body.style.right = "";
      }
    };
  }, [isMobile]);

  const introTitle = useMemo(() => {
    if (isGeneratingQuestion) {
      return "💭 Thinking about what you said...";
    }

    if (currentLiveQuestion?.shortQuestion) {
      return currentLiveQuestion.shortQuestion;
    }

    return "Do you want to add more?";
  }, [isGeneratingQuestion, currentLiveQuestion]);

  const introSubTitle = useMemo(() => {
    if (isGeneratingQuestion) {
      return "";
    }

    if (currentLiveQuestion?.questionContext) {
      return currentLiveQuestion.questionContext;
    }

    if (isPausedRecording) {
      return `Expand your ideas or click "Convert to Writing" to see your first draft.`;
    }
  }, [currentLiveQuestion, isGeneratingQuestion, isPausedRecording]);

  const ConvertToWritingButton = useMemo(() => {
    if (hasRequestedQuestion) {
      return (
        <PrimaryButton
          onClick={async () => {
            setInitialisingWritingFeedback(true);
            await stopRecording();
          }}
        >
          <img src={DocIcon} /> Convert to writing
        </PrimaryButton>
      );
    } else {
      return (
        <PrimaryButton
          onClick={async () => {
            setInitialisingWritingFeedback(true);
            await stopRecording();
          }}
        >
          <img src={DocIcon} /> Convert to writing
        </PrimaryButton>
      );
    }
  }, [hasRequestedQuestion]);

  const GetQuestionButton = useMemo(() => {
    if (hasRequestedQuestion) {
      return (
        <SecondaryButton onClick={getQuestion}>
          <img src={RepeatIcon} />
          New Question
        </SecondaryButton>
      );
    } else {
      return (
        <SecondaryButton onClick={getQuestion}>
          <img src={RepeatIcon} />
          Get Question
        </SecondaryButton>
      );
    }
  }, [hasRequestedQuestion, getQuestion]);

  if (initialisingWritingFeedback) {
    return (
      <Stack className="processing-circle-wrapper">
        <ProcessingCircle
          text="Transforming your ideas into writing..."
          subtext="It may take a few seconds! ☺️"
          customAnimation
        />
      </Stack>
    );
  }

  if (!currentLiveStory && !currentLiveQuestion) {
    return <></>;
  }

  return (
    <>
      <OnboardingCarousel
        flowName={OnboardingFlowType.ONBOARDING_FLOW_RECORD}
      />
      <IdeaFlowWrapper>
        <TitleWrapper>
          <h2 className="page-header">{introTitle}</h2>
          <QuestionAudioPlayer />
        </TitleWrapper>
        <IntroSubtitle>{introSubTitle}</IntroSubtitle>
        <RecordButtonWrapper>
          <Stack></Stack>
          <Stack className="justify-content-end">
            <StartBtnWrapper className="record-button-main">
              <WaveArcsCanvas
                width="200"
                height="200"
                ref={canvasRef}
                style={{ visibility: isRecording ? "visible" : "hidden" }}
              />
              <div id="squiggly-lines" className="squiggly-lines">
                <RightLineWrapper>
                  <RightLineLabel>Hit record and start talking</RightLineLabel>
                  <RightLine src={RightLineImage} />
                </RightLineWrapper>
              </div>

              {isInitialisingLiveStory ||
              isInitialising ||
              isGeneratingQuestion ||
              isPausing ? (
                <ProcessingCircle cleanAdaptiveSpinner />
              ) : (
                <StartBtn
                  className="my-auto mx-0"
                  onClick={async () => {
                    if (
                      paymentInfo.paymentTier ===
                      PaymentTier.PAYMENT_TIER_FREE_EXPIRED
                    ) {
                      showUpgradeToProModal();
                      return;
                    }
                    if (isRecording) {
                      pauseRecording();
                    } else {
                      if (!isPausedRecording) {
                        const squigglyLines =
                          document.getElementById("squiggly-lines");

                        const bottomButtonsWrapper = document.getElementById(
                          "bottom-buttons-wrapper"
                        );

                        const magicOutlineWrapper = document.getElementById(
                          "magic-outline-wrapper"
                        );

                        squigglyLines.classList.add("fade-out");
                        bottomButtonsWrapper.classList.add("fade-out");
                        magicOutlineWrapper.classList.add("fade-out");
                      }

                      await startRecording();
                    }
                  }}
                >
                  <Stack>
                    <StartBtnIcon
                      src={isRecording ? PauseIcon : MicrophoneIcon}
                    />
                    <span
                      style={{
                        fontSize: isSmallMobile ? 14 : isMobile ? 22 : "",
                      }}
                    >
                      {isRecording
                        ? `Pause`
                        : isPausedRecording
                        ? `Add More`
                        : "Record"}
                    </span>
                    {(isRecording || isPausedRecording) && <div>{time}</div>}
                  </Stack>
                </StartBtn>
              )}
              <br />
              <br />
              {isPausedRecording && !isGeneratingQuestion && (
                <ActionsWrapper>
                  {!!currentTranscriptChunk.current && GetQuestionButton}
                  {ConvertToWritingButton}
                </ActionsWrapper>
              )}
            </StartBtnWrapper>
          </Stack>
          <Stack></Stack>
        </RecordButtonWrapper>
        <div style={{ height: 40 }} />
        <BottomButtonsWrapper id="bottom-buttons-wrapper">
          <WriteNote />
          <InputLanguages />
        </BottomButtonsWrapper>
        <HintWrapper id="magic-outline-wrapper">
          <IdeaIcon width={24} height={24} />
          Not sure how to start?
          <LinkWrapper onClick={() => setMagicOutlineModal(true)}>
            Get an Outline
          </LinkWrapper>
        </HintWrapper>
      </IdeaFlowWrapper>
      <MagicOutlineModal
        isOpen={magicOutlineModal}
        setClose={() => setMagicOutlineModal(false)}
      />
      <br />
      <br />
      {liveTranscript}
    </>
  );
};

export default IdeaFlow;
