import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useMemo } from "react";
import { useAnonymousId } from "./use-anonymous-id";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR, { Key, SWRConfiguration } from "swr";
import { trackError, trackEvent } from "../components/datadog-logs";
import axiosRetry, { isNetworkError, isSafeRequestError } from "axios-retry";

const PUBLIC_ROUTES = ["ping"];
const API_PREFIX = "/api";
const ADMIN_API_PREFIX = "/api/admin";

const createPublicTransport = (isAdmin: boolean) =>
  axios.create({
    baseURL:
      process.env.REACT_APP_SERVER + (isAdmin ? ADMIN_API_PREFIX : API_PREFIX),
    withCredentials: true,
  });

export const publicTransport = createPublicTransport(false);

export const useTransport = (isAdmin: boolean) => {
  const [anonymousId] = useAnonymousId();

  const { logout, getAccessTokenSilently, isAuthenticated } = useAuth0();

  return useMemo(() => {
    const instance = createPublicTransport(isAdmin);

    instance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        if (PUBLIC_ROUTES.includes(config.url)) return config;

        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.Anonymous = anonymousId;
        return config;
      }
    );

    axiosRetry(instance, {
      retries: 3,
      retryDelay: (retryCount: number) => {
        return retryCount * 1000;
      },
      retryCondition: (error: AxiosError) => {
        return isNetworkError(error);
      },
      onRetry: (
        retryCount: number,
        error: AxiosError,
        config: InternalAxiosRequestConfig
      ) => {
        trackError("axios_retry", error, {
          retryCount,
          url: config.url,
          data: config.data,
        });
      },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error: any) => {
        if (error.message?.includes("missing_refresh_token")) {
          trackEvent("missing_refresh_token", error);

          localStorage.removeItem("account");
          logout({ logoutParams: { returnTo: window.location.origin } });
        } else if (error.error === "login_required") {
          trackError("login_required", error);

          localStorage.removeItem("account");
          logout({ logoutParams: { returnTo: window.location.origin } });
        } else if (isNetworkError(error)) {
          trackError("Network Error", error);
        } else {
          trackError("server_request_error", error);
          return Promise.reject(error);
        }
      }
    );

    return { transport: instance };
  }, [anonymousId, getAccessTokenSilently, logout, isAuthenticated]);
};

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    "initialData"
  > {
  initialData?: Data;
}

export const useTransportSWR = <Data = any, Error = any>(
  transport: AxiosInstance,
  path: Key,
  config?: Config<Data, Error>
) => {
  const {
    data: response,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    path,
    (p) => transport.get<Data>(p),
    config
  );

  return {
    data: response && response.data,
    response,
    error,
    isLoading,
    isValidating,
    revalidate: mutate,
  };
};
