import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  position: relative;
  .edit-output-icon {
    position: absolute;
    right: 14px;
    top: 12px;
    &:hover {
      fill: #fa8152;
    }
  }
  textarea {
    border-radius: 8px;
    text-align: start;
    font-family: 'Metropolis';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: 132px;
    padding: 12px 40px 12px 14px;
    border: 1px solid #C1C1C1;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    resize: none;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #C1C1C1;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;