import styled from "styled-components";

export const NoContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: 100px;
  img {
    width: 306px;
    height: 235px;
  }
  h2 {
    color: #101212;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20.8px;
    }
    img {
      width: 240px;
      height: 185px;
    }
  }
`;

export const ShareableDocumentWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 2rem;
  .accordion {
    height: fit-content;
  }
  .accordion-item {
    border: none !important;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05) !important;
    height: fit-content;
    border-radius: 17px !important;
    margin: 0;
    min-width: unset;
    width: unset;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    margin-top: 0;
  }
`;

export const CopyLinkButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

export const LinkToDocs = styled.div`
  color: #ff6d34;
  font-family: "Metropolis";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const DocInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 16px;
  border-radius: 10px;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  padding: 16px;
`;
export const DocInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const DocInfoRow = styled.div`
  font-family: "Metropolis";
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  color: #101212;
  span {
    font-weight: 400;
    line-height: 130%;
  }
`;
export const DocHint = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
  color: #ababab;
  font-family: "Metropolis";
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  p {
    margin-bottom: 0;
  }
`;

export const Userpic = styled.div`
  width: 40px;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #101212;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 360px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: -0.308px;
  }
`;

export const ToolDescription = styled.p`
  font-size: 16px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
  }
`;
