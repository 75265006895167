import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Icons
import { ExamplesIcon, HomeIcon, DocIcon, SettingsIcon } from "../IconsSvg";
// Styles
import {
  BottomNavBarWrapper,
  ButtonsWrapper,
  MenuItem,
  Text,
} from "./bottom-nav-bar.style";

interface MenuConfig {
  route: string;
  icon: React.ElementType;
  text: string;
}

const menuItems: MenuConfig[] = [
  { route: "/", icon: HomeIcon, text: "Home" },
  { route: "/documents", icon: DocIcon, text: "My Docs" },
  { route: "/whytalk", icon: ExamplesIcon, text: "Why talk?" },
];

export const BottomNavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isPWA, isMobile, isSmallMobile } = useGlobal();

  const isRouteActive = (route: string): boolean =>
    location.pathname === route ||
    (location.pathname.startsWith(route) && route !== "/");

  useEffect(() => {
    if (isPWA && isMobile) {
      const app = document.querySelector(".app") as HTMLElement | null;
      app.style.paddingBottom = "88px";
    }
  }, []);
  const smallMobileStyles = isSmallMobile ? { width: 20, height: 20 } : {};
  return (
    <BottomNavBarWrapper id="bottom-nav-bar" $addHeight={isPWA}>
      <ButtonsWrapper>
        {menuItems.map(({ route, icon: Icon, text }) => (
          <MenuItem key={route} onClick={() => navigate(route)}>
            <Icon
              {...smallMobileStyles}
              $color={isRouteActive(route) ? "" : "#919191"}
            />
            <Text $inactive={!isRouteActive(route)}>{text}</Text>
          </MenuItem>
        ))}
      </ButtonsWrapper>
    </BottomNavBarWrapper>
  );
};
