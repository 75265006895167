import styled from "styled-components";

export const PricingWrapper = styled.div`
  width: 100%;
  margin-top: 48px;
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
`;
 
export const Title = styled.div`
  color: #101212;
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.408px;
  margin-bottom: 32px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    letter-spacing: -0.528px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.div`
  color: #6F728F;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: -0.396px;
  margin-bottom: 32px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: -0.308px;
    margin-bottom: 16px;
  }
`;

export const TabsWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

export const PricingTabs = styled.div`
  display: inline-flex;
  self-align: center;
  padding: 8px;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #FFF;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
    max-width: 328px;
  }
`;

export const TabItem = styled.div<{ $isActive: boolean }>`
  display: flex;
  width: 180px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  border-radius: 100px;
  cursor: pointer;
  transition: all .15s;
  ${props => props.$isActive && `
    color: #FFF;
    background: #FF6D34;
  `}
  @media screen and (max-width: 767px) {
    max-width: 140px;
    width: 100%;
    min-width: 125px;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Card = styled.div<{$isHighlighted: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  border-radius: 24px;
  background: ${props => props.$isHighlighted ? "#FF6D34" : "white"};
  box-shadow: 0px 4px 30px 0px rgba(54, 54, 54, 0.10);
  .card-top-block {
    background: ${props => props.$isHighlighted ? "#FF6D34" : "#FFF8F5"};
    color: ${props => props.$isHighlighted ? "white" : "#6F728F"};
    .plan-price {
        color: ${props => props.$isHighlighted ? "white" : "#101212"};
      }
    }
    button {
      background: ${props => props.$isHighlighted ? "white" : ""};
      color: ${props => props.$isHighlighted ? "#FF6D34" : ""};
      &:hover {
        background: ${props => props.$isHighlighted ? "#FFF7F4" : ""};
      }
    }
  }
  .card-bottom-block {
    color: ${props => props.$isHighlighted ? "white" : "#101212"};
    > div:first-child {
      svg {
        path {
          fill: ${props => props.$isHighlighted ? "white" : "#FF6D34"};
        }
      }
    }
    > div:last-child {
      color: ${props => props.$isHighlighted ? "white" : "#FF6D34"};
      svg {
        path {
          stroke: ${props => props.$isHighlighted ? "white" : "#FF6D34"};
        }
      }
    } 
  }
`;

export const CardTopBlock = styled.div`
  display: flex;
  padding: 24px;
  height: 236px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 24px 24px 0px 0px;
  @media screen and (max-width: 767px) {
    height: unset;
  }
`;

export const PlanInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: 767px) {
    gap: 10px;
  }
`;

export const PlanName = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  letter-spacing: -0.396px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.352px;
  }
`;

export const PlanPrice = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -0.704px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: -0.528px;
  }
`;

export const PlanDescription = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 15.6px;
    letter-spacing: -0.264px;
  }
`;

export const CardBottomBlock = styled.div`
  display: flex;
  padding: 20px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 50px;
`;

export const FeaturesItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: -0.308px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const SiteLink = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  margin-right: 24px;
  color: #FF6D34;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  svg {
    path {
      stroke: #FF6D34;
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;
