import { toast } from "react-toastify";

export const useToast = () => {
  const dismissToasts = () => toast.dismiss();
  const failureToast = (text, props = {}) => {
    toast.error(text, {
      ...props,
    });
  };
  return { failureToast, dismissToasts };
};
