import { useEffect, useState, useLayoutEffect } from 'react';

export const useScrollbarCompensation = (): void => {
  const [scrollbarWidth, setScrollbarWidth] = useState<number>();

  useLayoutEffect(() => {
    const updateBodyMargin = () => {
      if (!scrollbarWidth) return null;

      if (window.innerWidth - document.documentElement.clientWidth) {
        document.body.style.marginRight = '';
      } else {
        document.body.style.marginRight = `${scrollbarWidth}px`;
      }
    };

    updateBodyMargin();

    const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === document.documentElement) {
          updateBodyMargin();
        }
      }
    };

    const resizeObserver = new ResizeObserver(observerCallback);
    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [scrollbarWidth]);

  useEffect(() => {
    const getScrollbarWidth = () => {
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.width = '100px';
      outer.style.overflow = 'scroll';
      document.body.appendChild(outer);
      const inner = document.createElement('div');
      inner.style.width = '100%';
      outer.appendChild(inner);
      const scrollbarWidth = outer.offsetWidth - outer.clientWidth;
      document.body.removeChild(outer);
      return scrollbarWidth;
    };
    setScrollbarWidth(getScrollbarWidth());
  }, []);
};
