import { useAccount } from "@considr-it/storied-shared";
import { MicRecorderProvider } from "./hooks/use-mic-recorder";
import App from "./App";
import { ModalProvider } from "./hooks/use-modal";
import { DatadogLogging } from "@considr-it/storied-shared";
import { DocumentProvider } from "./hooks/use-document";
import { useAuth0 } from "@auth0/auth0-react";
import CompleteProfileModal from "./components/Modals/CompleteProfileModal";
import { LoadingAnimation } from "./components/LoadingAnimation";
import { OnboardingProvider } from "./hooks/use-onboarding";
import { usePayment } from "./hooks/use-payment";

function AppWrapper() {
  const { account, isValidatingAccount, isLoadingAccount, accountMetaData } =
    useAccount();
  const { paymentInfo } = usePayment();
  const { isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    !isValidatingAccount &&
    !isLoadingAccount &&
    account?.isAnonymous
  ) {
    return <CompleteProfileModal />;
  }

  return (
    <div className="app">
      {!account || !accountMetaData || !paymentInfo ? (
        <LoadingAnimation />
      ) : (
        <DocumentProvider>
          <ModalProvider>
            <DatadogLogging />
            <MicRecorderProvider>
              <OnboardingProvider>
                <App />
              </OnboardingProvider>
            </MicRecorderProvider>
          </ModalProvider>
        </DocumentProvider>
      )}
    </div>
  );
}

export default AppWrapper;
