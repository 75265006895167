import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// Custom hooks
import { useDocument } from "../../hooks/use-document";
import { useAccount, useGlobal } from "@considr-it/storied-shared";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
// Components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import LoginButton from "../LoginButton";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { Chevron } from "../Chevron";
// Pictures
import Logo from "./logo.svg";
// Icons
import { ExitIcon, SettingsIcon } from "../IconsSvg";
// Styles
import {
  ButtonWrapper,
  LogoIcon,
  ItemWrapper,
  MenuGroup,
  MenuLink,
  NavbarWrapper,
  UserInfo,
  Userpic,
  PlaceholderPic,
  UserName,
  UserEmail,
  MobileMenuLink,
} from "./nav-bar.style";
import { usePayment } from "../../hooks/use-payment";
import { PaymentTier } from "@considr-it/storied-enums";

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { account, logout } = useAccount();
  const { isMobile } = useGlobal();
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const { topicsCount } = useDocument();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [imageIsBroken, setImageIsBroken] = useState<boolean>(false);
  const { paymentInfo } = usePayment();

  const toggleDropdown = () => {
    setIsOpen((s) => !s);
  };

  const topicsCountStr = topicsCount > 0 ? ` (${topicsCount})` : "";

  const getStateColor = (route: string): string => {
    if (
      location.pathname === route ||
      (location.pathname.startsWith(route) && route !== "/")
    ) {
      return "#FF6D34";
    }
    return "#101212";
  };

  return (
    <NavbarWrapper>
      <Navbar>
        <Navbar.Brand>
          <a href="/">
            <LogoIcon src={Logo} />
          </a>
        </Navbar.Brand>
        <MenuGroup>
          <MenuLink
            style={{ color: getStateColor("/whytalk") }}
            onClick={() => navigate("/whytalk")}
          >
            Why talk?
          </MenuLink>
          {[
            PaymentTier.PAYMENT_TIER_FREE,
            PaymentTier.PAYMENT_TIER_FREE_EXPIRED,
            PaymentTier.PAYMENT_TIER_PRO_TRIAL,
          ].includes(paymentInfo.paymentTier) && (
            <MenuLink
              style={{ color: getStateColor("/pricing") }}
              onClick={() => navigate("/pricing")}
            >
              Pricing
            </MenuLink>
          )}
          <MenuLink
            style={{ color: getStateColor("/") }}
            onClick={() => navigate("/")}
          >
            Start
          </MenuLink>
          <MenuLink
            style={{ color: getStateColor("/documents") }}
            onClick={() => navigate("/documents")}
          >
            {isMobile
              ? "My documents" + topicsCountStr
              : "Docs" + topicsCountStr}
          </MenuLink>
          <ButtonWrapper className="d-flex justify-content-center align-items-center">
            {account.isAnonymous && <LoginButton />}
          </ButtonWrapper>

          {!account?.isAnonymous && !isMobile && (
            <Dropdown align="end" onToggle={toggleDropdown}>
              <Dropdown.Toggle variant="transparent">
                {account?.profile?.name}
                <Chevron isOpen={isOpen} special={false} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <UserInfo>
                    <Userpic>
                      <ReturnJSX
                        if={!account?.profile?.imageUrl || imageIsBroken}
                        else={
                          <img
                            src={account?.profile?.imageUrl}
                            onError={(e) => setImageIsBroken(true)}
                          />
                        }
                      >
                        <PlaceholderPic>
                          {account?.profile?.name.split(" ")[0][0]}
                          {account?.profile?.name.split(" ")?.[1]?.[0]}
                        </PlaceholderPic>
                      </ReturnJSX>
                    </Userpic>
                    <div>
                      <UserName>{account?.profile?.name}</UserName>
                      <UserEmail>{account?.mainEmail}</UserEmail>
                    </div>
                  </UserInfo>
                </Dropdown.Item>
                <hr />
                <Dropdown.Item onClick={() => navigate("/profile")}>
                  <ItemWrapper>
                    <SettingsIcon />
                    Settings
                  </ItemWrapper>
                </Dropdown.Item>
                <hr />
                <Dropdown.Item
                  onClick={() => {
                    onPresentConfirmModal({
                      confirmationMessage: "Are you sure you want to log out?",
                      actions: [
                        <PrimaryButton onClick={logout}>Log out</PrimaryButton>,
                        <SecondaryButton onClick={onDismiss}>
                          Cancel
                        </SecondaryButton>,
                      ],
                    });
                  }}
                >
                  <ItemWrapper>
                    <ExitIcon width={24} height={24} />
                    Log out
                  </ItemWrapper>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {!account?.isAnonymous && isMobile && (
            <MobileMenuLink href="/profile">
              {account?.profile?.name}
              <SettingsIcon />
            </MobileMenuLink>
          )}
        </MenuGroup>
      </Navbar>
    </NavbarWrapper>
  );
};
