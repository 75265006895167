import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
import { useParams } from "react-router-dom";
// Helpers
import { sortByDate } from "../utils/utils";
// Constants
import {
  Base,
  LiveQuestion,
  LiveStory,
  Output,
  SharedOutput,
  SharedOutputMetaData,
  Topic,
} from "@considr-it/storied-entities";
import { useDocument } from "./use-document";

export const useDocs = () => {
  const { filter, searchTerm } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { refreshTopicsCount } = useDocument();

  const [editedWithAiList, setEditedWithAiList] = useState<Topic[]>([]);
  const [inProgressList, setInProgressList] = useState<(LiveStory | Topic)[]>(
    []
  );

  const [draftsList, setDraftsList] = useState<LiveStory[]>([]);
  const [sharedList, setSharedList] = useState<SharedOutputMetaData[]>([]);

  const docTypesMapRef = useRef<Map<string, string>>(new Map());

  const { transport } = useGlobal();

  const getDocType = (obj: Base) => {
    return docTypesMapRef.current.get(obj.id);
  };

  const preProcessDocs = async () => {
    const [{ data: topics }, { data: liveStories }, { data: sharedOutputs }] =
      await Promise.all([
        transport.get<Topic[]>("/topicsList"),
        transport.get<LiveStory[]>("/liveStoriesList"),
        transport.get<SharedOutputMetaData[]>("/sharedOutputsMetaDataList"),
      ]);

    docTypesMapRef.current.clear();

    for (const t of topics) {
      docTypesMapRef.current.set(t.id, "topic");
    }

    for (const ls of liveStories) {
      docTypesMapRef.current.set(ls.id, "live-story");
    }

    for (const so of sharedOutputs) {
      docTypesMapRef.current.set(so.id, "shared-output");
    }

    setEditedWithAiList(
      sortByDate(
        topics.filter((t: Topic) => t.outputs?.length > 1),
        "updated"
      ) as Topic[]
    );

    setInProgressList(
      sortByDate(
        [
          ...liveStories.filter((ls: LiveStory) => !ls.isDraft),
          ...topics.filter((t: Topic) => t.outputs.length === 1),
        ],
        "updated"
      ) as (Topic | LiveStory)[]
    );

    setDraftsList(
      sortByDate(
        [...liveStories.filter((ls: LiveStory) => !!ls.isDraft)],
        "updated"
      ) as LiveStory[]
    );

    setSharedList(
      sortByDate(sharedOutputs, "updated") as SharedOutputMetaData[]
    );

    setIsLoading(false);
  };

  useEffect(() => {
    preProcessDocs();
  }, []);

  const removeDoc = useCallback(
    async (obj: Base) => {
      const docType = getDocType(obj);

      setSharedList((prev) => prev.filter((so) => so.id !== obj.id));
      setEditedWithAiList((prev) => prev.filter((t) => t.id !== obj.id));
      setInProgressList((prev) => prev.filter((ls) => ls.id !== obj.id));
      setDraftsList((prev) => prev.filter((ls) => ls.id !== obj.id));

      switch (docType) {
        case "topic":
          await transport.delete(`/topic/${obj.id}`);
          break;

        case "live-story":
          await transport.delete(`/liveStory/${obj.id}`);
          break;

        case "shared-output":
          await transport.delete(`/sharedOutputMetaData/${obj.id}`);
          break;

        default:
          break;
      }

      refreshTopicsCount();

      docTypesMapRef.current.delete(obj.id);
    },
    [transport]
  );

  const convertLiveStory = async (liveStory: LiveStory) => {
    const {
      data: { id },
    } = await transport.post<Topic>("/createTopic", {
      liveStoryId: liveStory.id,
    });

    refreshTopicsCount();

    return id;
  };

  const getTextForObject = (obj: Base) => {
    const docType = getDocType(obj);

    switch (docType) {
      case "topic":
        const topic = obj as Topic;

        return (
          (topic.outputs as Output[])
            .map((o: Output) => `${o.title}\n${o.text}`)
            .join("\n") + topic.transcript || ""
        );

      case "live-story":
        const liveStory = obj as LiveStory;

        return (
          liveStory.subTranscripts.join("\n") +
          (liveStory.liveQuestions as LiveQuestion[]).map(
            (lq: LiveQuestion) => {
              return `${lq.shortQuestion}\n${lq.questionContext}\n${lq.spokenQuestion}`;
            }
          )
        );

      case "shared-output":
        const sharedOutput = (obj as SharedOutputMetaData)
          .sharedOutput as SharedOutput;

        return `${sharedOutput.title}\n${sharedOutput.tldr}\n${sharedOutput.text}`;

      default:
        return "";
    }
  };

  const currentDocsList = useMemo(() => {
    let docsList = [];

    switch (filter) {
      case "edited-with-ai":
        docsList = editedWithAiList;
        break;
      case "in-progress":
        docsList = inProgressList;
        break;
      case "drafts":
        docsList = draftsList;
        break;
      case "shared-docs":
        docsList = sharedList;
        break;
      case "all-docs":
        docsList = sortByDate(
          [
            ...editedWithAiList,
            ...inProgressList,
            ...sharedList,
            ...draftsList,
          ],
          "updated"
        );
        break;
    }

    return docsList.filter((o) =>
      getTextForObject(o)
        .toLowerCase()
        .includes(searchTerm?.toLowerCase() || "")
    );
  }, [editedWithAiList, inProgressList, sharedList, filter, searchTerm]);

  return {
    isLoading,

    currentDocsList,

    editedWithAiList,
    inProgressList,
    sharedList,
    draftsList,

    getDocType,
    removeDoc,
    convertLiveStory,
  };
};
