import React, { useRef, useState, useEffect, ChangeEvent } from "react";
// Icons
import { BsPencil } from "react-icons/bs";
import { FaFloppyDisk } from "react-icons/fa6";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Components
import { ProcessingCircle } from "../ProcessingCircle";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { storiedOrange } from "../../constants";
// Constants
const TLDR_PLACEHOLDER = `I've recorded some thoughts. Click the link to take a look`;
// Other
import { Output } from "@considr-it/storied-entities";
// Styles
import { ModalContentWrapper } from "./shared-output-text-area.style";

interface SharedOutputTextAreaProps {
  output: Output;
}

export const SharedOutputTextArea: React.FC<SharedOutputTextAreaProps> = ({
  output,
}) => {
  const [tldr, setTldr] = useState<string>(output.tldr || TLDR_PLACEHOLDER);
  const [textAreaValue, setTextAreaValue] = useState<string>(tldr);
  const [textAreaInactive, setTextAreaInactive] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { transport } = useGlobal();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTextAreaValue(tldr);
  }, [tldr]);

  const setTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
    setTextAreaInactive(false);
  };

  const saveTldr = async () => {
    if (textAreaValue !== tldr) {
      setIsLoading(true);
      await transport.patch(`/output/${output.id}`, {
        payload: { tldr: textAreaValue },
      });
      setTldr(textAreaValue);
      setIsLoading(false);
      setTextAreaInactive(true);
    }
  };

  return (
    <ModalContentWrapper>
      <ReturnJSX if={isLoading}>
        <div style={{ position: "absolute", top: 12, right: 14 }}>
          <ProcessingCircle circleSize="18px" />
        </div>
      </ReturnJSX>

      <ReturnJSX if={!isLoading && textAreaValue !== tldr}>
        <FaFloppyDisk
          size="18px"
          className="edit-output-icon"
          onClick={saveTldr}
          style={{ color: storiedOrange, cursor: "pointer" }}
        />
      </ReturnJSX>

      <ReturnJSX if={!isLoading && textAreaValue === tldr}>
        <BsPencil
          size="18px"
          onClick={() => textAreaRef.current?.focus()}
          className="edit-output-icon"
          style={{
            color: textAreaInactive ? "#ABABAB" : storiedOrange,
            cursor: "pointer",
          }}
        />
      </ReturnJSX>
      <textarea
        onChange={setTextAreaHandler}
        value={textAreaValue}
        onFocus={() => setTextAreaInactive(false)}
        onBlur={() => setTextAreaInactive(true)}
        ref={textAreaRef}
      />
    </ModalContentWrapper>
  );
};