import { useAccount } from "@considr-it/storied-shared";
import { useDocument } from "../../hooks/use-document";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { ConfirmationModal } from "../Modals/ConfirmModal";

export const LoginNotification = () => {
  const { account, login } = useAccount();
  const { initialisingWritingFeedback, streaming } = useIdeaFlow();
  const { topicsCount } = useDocument();

  if (
    account.isAnonymous &&
    !initialisingWritingFeedback &&
    !streaming &&
    topicsCount > 0
  ) {
    return (
      <ConfirmationModal
        params={{
          confirmationMessage: "Login to save your work!",
          description:
            "You've got great ideas! Create an account or login to access your data on any device.",
          actions: [
            <div>
              <PrimaryButton fullWidth onClick={() => login("login")}>
                Login
              </PrimaryButton>
              <br />
              <SecondaryButton fullWidth onClick={() => login("signup")}>
                Sign Up
              </SecondaryButton>
            </div>,
          ],
          withCloseBtn: false,
          disableOnHide: true,
        }}
      />
    );
  }

  return <></>;
};
