// Custom hooks
import { usePayment } from "./use-payment";
// Constants
import { PaymentTier } from "@considr-it/storied-enums";

export const useFeaturesLocking = () => {
  const { paymentInfo } = usePayment();
  const isLocked =
    paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_FREE ||
    paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_FREE_EXPIRED;

  return { isLocked };
};
