import { useEffect, useState } from "react";
import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import packageInfo from "../package.json";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, cssTransition } from "react-toastify";
// Custom hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
import { useMicRecorder } from "./hooks/use-mic-recorder";
import { useDocument } from "./hooks/use-document";
import { useConfirmModal } from "./hooks/use-confirm-modal";
import { useScrollbarCompensation } from "./hooks/use-compensate-scroll-bar-width";
// Pages
import PWAPrompt from "react-ios-pwa-prompt";
import { WritingFeedback } from "./pages/WritingFeedback";
import { MyDocuments } from "./pages/MyDocuments";
import { ShareableDocument } from "./pages/ShareableDocument";
import { WhyTalk } from "./pages/WhyTalk";
import { Pricing } from "./pages/Pricing";
import { Profile } from "./pages/Profile";
import { Draft } from "./pages/Draft";
// Components
import IdeaFlow from "./components/IdeaFlow";
import { NavBar } from "./components/NavBar";
import { ConfirmationModal } from "./components/Modals/ConfirmModal";
import { PrimaryButton, SecondaryButton } from "./components/Buttons";
import { FancyBackground } from "./components/FancyBackground";
import { BottomNavBar } from "./components/BottomNavBar";
import { LinkedInCallback } from "./pages/LinkedInCallback";
import { GoogleCallback } from "./pages/GoogleCallback";
import { NoSoundWarningBanner } from "./components/NoSoundWarningBanner";
// Styles
import "react-toastify/dist/ReactToastify.css";
import { TrialAccountNotification } from "./components/TrialAccountNotification";
import { LoginCallback } from "./pages/LoginCallback";
import { IdeaFlowProvider } from "./hooks/use-idea-flow";
import { LoginNotification } from "./components/LoginNotification";
import { SharedLiveStoryRedirect } from "./pages/SharedLiveStoryRedirect";
const AppContainer = styled.div`
  max-width: 1184px;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(1.5rem*.5);
  padding-right: calc(1.5rem*.5);
  width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 360px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
`;

function App() {
  const { pingData, isMobile } = useGlobal();
  const { isAuthenticated } = useAuth0();
  const { account, setAccount, login } = useAccount();
  const { isBusy } = useMicRecorder();
  const { topicsCount } = useDocument();
  const { onPresentConfirmModal } = useConfirmModal();
  const [modalPresented, setModalPresented] = useState<boolean>(false);

  useScrollbarCompensation();

  useEffect(() => {
    if (
      !isAuthenticated &&
      account.isAnonymous &&
      account.loggedInHistory?.length > 0 &&
      modalPresented === false
    ) {
      setModalPresented(true);
      onPresentConfirmModal({
        confirmationMessage: "Welcome back!",
        description: `Continue with ${account.loggedInHistory[0]} to save your work`,
        actions: [
          <div>
            <PrimaryButton fullWidth onClick={() => login("login")}>
              Login
            </PrimaryButton>
            <div style={{ height: 16 }} />
            <SecondaryButton fullWidth onClick={() => login("signup")}>
              Sign Up
            </SecondaryButton>
          </div>,
        ],
        withCloseBtn: false,
        disableOnHide: true,
      });
    }
  }, [account, isAuthenticated, modalPresented]);

  if (
    (packageInfo.version.split(".")[0] !== pingData.version.split(".")[0] ||
      packageInfo.version.split(".")[1] !== pingData.version.split(".")[1]) &&
    !isBusy
  ) {
    return (
      <ConfirmationModal
        params={{
          confirmationMessage: `You have an outdated version of the app (v${packageInfo.version})`,
          actions: [
            <PrimaryButton
              onClick={() => {
                setAccount(null);

                window.location.reload();
              }}
            >
              Reload
            </PrimaryButton>,
          ],
        }}
      ></ConfirmationModal>
    );
  }

  return (
    <AppContainer>
      <ToastContainer
        closeButton={false}
        hideProgressBar={true}
        autoClose={false}
        transition={cssTransition({
          enter: "dummy",
          exit: "dummy",
          collapse: false,
        })}
      />

      <NoSoundWarningBanner />
      <TrialAccountNotification />

      {topicsCount >= 2 && (
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
        />
      )}
      <BrowserRouter>
        <IdeaFlowProvider>
          <LoginNotification />
          <NavBar />
          <Routes>
            <Route index element={<IdeaFlow />} />
            <Route path="linkedin-callback" element={<LinkedInCallback />} />
            <Route path="google-callback" element={<GoogleCallback />} />
            <Route path="login-callback" element={<LoginCallback />} />
            <Route path="whytalk" element={<WhyTalk />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="draft/:liveStoryId?" element={<Draft />} />
            <Route path="profile/:category?" element={<Profile />} />
            <Route
              path="documents/:filter?/:searchTerm?"
              element={<MyDocuments />}
            />
            <Route
              path="topic/:topicId/:outputId?"
              element={<WritingFeedback />}
            />
            <Route path="s/:shortId" element={<ShareableDocument />} />
            <Route path="sls/:shortId" element={<SharedLiveStoryRedirect />} />
          </Routes>
          <BottomNavBar />
          <FancyBackground />
        </IdeaFlowProvider>
      </BrowserRouter>
    </AppContainer>
  );
}

export default App;
